import 'picturefill';
import 'lazysizes';
import 'lazysizes/plugins/print/ls.print';
import 'objectFitPolyfill/dist/objectFitPolyfill.basic.min';

// Object-fit polyfill
window.addEventListener('lazyloaded', (event) => {
  if (event.target.parentNode && event.target.parentNode.classList.contains('image--cover')) {
    window.objectFitPolyfill(event.target);
  }

  // Add loading finished class
  if (event.target.parentNode && event.target.parentNode.classList.contains('loading')) {
    event.target.parentNode.classList.add('loading--finished');
  }
});
